import React from 'react'
import styled from 'styled-components'

import glass from '../../assets/icons/ui/glass.png'

export default function SearchBar() {

  return(
    <SearchBarContainer>
      <SearchIcon
        src={glass}
        height={20}
      />
      <SearchInput
        type='text'
        placeholder='Buscar'
      />
    </SearchBarContainer>
  )
}

const SearchBarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0px 0px 0px 40px;
  align-items: center;
`
const SearchIcon = styled.img`
  margin: 0px 10px 0px 0px;
`

const SearchInput = styled.input`
  border-radius: 5px;
  height: 20px;
`