import React, { useLayoutEffect, useState } from "react";
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'
import useViewport from "../../hooks/useViewport"

import Cookies from 'universal-cookie'

import AuthAdvice from "../../components/advice/authAdvice";

import  Verificacion  from './verification'
import SignUpSuccess from './signUpSuccess'
import Register from './register'


const cookies = new Cookies();
export type TsignUpStep = 'signup' | 'verificacion' | 'signUpSucces'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function SignUp() {
  const config = useConfig()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  const [ step, setStep ] = useState<TsignUpStep>('signup')

  const changeRegisterStep = (step: TsignUpStep) => {
    setStep(step)
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  useLayoutEffect(() => {
    if (cookies.get(config.app.COOKIE_SIGNUP)) {
      setStep('verificacion')
    }
  }, []);

  return (
    <SignUpContainer isMobile={isMobile}>

      <Card isMobile={isMobile}>
        { step === 'signup' && <Register changeRegisterStep={changeRegisterStep}/>}
        { step === 'verificacion' &&  <Verificacion changeRegisterStep={changeRegisterStep}/> }
        { step === 'signUpSucces' &&  <SignUpSuccess changeRegisterStep={changeRegisterStep}/> }
      </Card>

      <AuthAdvice/>

    </SignUpContainer>
  );
}

interface TsignUpContainer { isMobile: boolean }
const SignUpContainer = styled.div<TsignUpContainer>`
  display: flex;
  flex-flow: ${(args) => args.isMobile ? 'column nowrap' : 'row nowrap' };
  gap: 100px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
`

interface Tcard { isMobile: boolean }
const Card = styled.div<Tcard>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 100%;
  max-width: 350px;

  min-width: 250px;
  border-radius: 10px;
  padding: 40px 45px;
  padding: ${(args) => args.isMobile ? '40px 0px' : '40px 45px' };
  margin: 0px 0px 30px 0px; 
`