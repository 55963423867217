import React, { useState, useRef, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import useConfig from '../../hooks/useConfig'
import styled from 'styled-components'
import { TsignUpStep } from './SignUp'
import Cookies from 'universal-cookie'
import { AxiosError } from 'axios'
import { signUpRegister } from '../../services/auth/api'

import loadingGif from '../../assets/gifs/loading.gif'
import { TresponseError } from '../../services/auth/userApiTypes'

const cookies = new Cookies()

interface message {
  type: 'info' | 'error' | 'none',
  message: string | undefined,
}

interface Tregister { changeRegisterStep: (step: TsignUpStep) => void }
export default function Register(props: Tregister) {
  const config = useConfig()
  const [ message, setMessage ] = useState<message>({ type: 'none', message: '' })

  const [ enableSignUp, setEnableSignUp ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  const emailInput = useRef<HTMLInputElement>(null);
  const passInput = useRef<HTMLInputElement>(null);
  const phoneInput = useRef<HTMLInputElement>(null);


  function inputChange() {
    if (emailInput.current && passInput.current && phoneInput.current) {

      if (emailInput.current.value !== '' && passInput.current.value !== '' && phoneInput.current.value !== '' )  {
        setEnableSignUp(true);
      } else {
        setEnableSignUp(false);
      }
    }
  }
  
  function registrarse(event: React.SyntheticEvent) {
    setMessage({ type: 'none', message: '' });
    setLoading(true);
    event.preventDefault();

    const target = event.target as typeof event.target & {
      phone: { value: string };
      email: { value: string };
      password: { value: string };
    };
  
    const payload = {
      type: "signup",
      phone_number: target.phone.value,
      email: target.email.value,
      password: target.password.value
    }

    signUpRegister(payload)
    .then(resp => {
      console.log('Register: ', resp)
      setLoading(false);
      cookies.set(config.app.COOKIE_SIGNUP, target.email.value);
      props.changeRegisterStep('verificacion')
    })
    .catch(err => {
      let error: AxiosError<TresponseError> = err
      setLoading(false)
      setMessage({ type: 'error', message: error.response?.data.message })
      console.log('ResetPassNewPass Error: ', err)
    })
  }

  return (
    <FormContainer>

      <Title>Registrate</Title>

      <Form onSubmit={registrarse} >

        <Input
          type="email"
          ref={emailInput}
          onChange={() => { inputChange() }}
          placeholder="Email"
          name="email"
        />

        <Input
          type="password"
          ref={passInput}
          onChange={() => { inputChange() }}

          placeholder="Contraseña"
          name="password"
        />

        <PhoneInputContainer>
          <CountryCodeInput
            type='text'
            placeholder='+591'
            disabled={true}
          />
          
          <PhoneInput
            type="tel"
            ref={phoneInput}
            onChange={() => { inputChange() }}
            placeholder="Telefono"
            name="phone"
          />
        </PhoneInputContainer>

        <p className={ message.type === 'error' ? 'auth-messageError' : 'auth-hidden' } >
            { message.message }
        </p>

        <SignUpButton
          type="submit"
          ready={enableSignUp}
          disabled={!enableSignUp || loading ? true : false}
        >
          { loading ? '' : 'Continuar' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </SignUpButton>

      </Form>

      <GoToLogin>
        Ya tienes una cuenta?
        <Link to='/login' style={{textDecoration: 'none'} } >
          <HighLighted>
            Inicia Sesión
          </HighLighted>
        </Link>
      </GoToLogin>
        
    </FormContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const Title = styled.p`
  font-weight: 500;
  font-size:  25px;
  color: white;
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 0px auto 10px auto;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

interface TsignUpButton { ready: boolean }
const SignUpButton = styled.button<TsignUpButton>`
  width: 100%;
  background-color: #FF925C;
  
  border-radius: 10px;
  font-size: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  margin-top: 40px;
  ${(args) => args.ready && `
    background-color: #fe7027;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `

interface Tinput { error: boolean }
const Input = styled.input`
  width: 90%;
  border: none;
  border-radius: 5px;
  //border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0px 0px 0px;
  padding: 5px 5px 5px 15px;

  &:focus {
    outline: 1px solid black;
  }
`
const PhoneInputContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 96%;
  gap: 10px;
  
`
  const CountryCodeInput = styled.input`
    flex-grow: 1;
    width: 50px;
    border: none;
    border-radius: 5px;
    background-color: white;
    font-size: 13px;
    line-height: 30px;
    margin: 15px 0px 0px 0px;
    padding: 5px 5px 5px 15px;
  `
  const PhoneInput = styled(Input)`
    width: auto;
    flex-grow: 4;
  `


const GoToLogin = styled.p`
  align-self: flex-end;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-align: right;
  text-decoration: none !important;
  color: white;
`
const HighLighted = styled.span`
  color: #fe7027;
  font-weight: 600;
  margin-left: 5px;
`