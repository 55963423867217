import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//import { AppThunk, AppDispatch } from "./store";
import { Tview, TuiState } from "./types";

const views: Tview[] = [
  { title: 'Home', path: '/', theme: 'ligth', show: 'never' },
  { title: 'Comprar', path: '/comprar', theme: 'ligth', show: 'ever' },
  { title: 'Vender', path: '/vender', theme: 'ligth', show: 'ever' },
  { title: 'Sobre Carp10', path: '/about', theme: 'ligth', show: 'ever' },
  { title: 'Registo', path: '/registro', theme: 'ligth', show: 'never' },
  { title: 'Login', path: '/login', theme: 'ligth', show: 'noLogged' },
  { title: 'Mi Cuenta', path: '/micuenta', theme: 'ligth', show: 'logged' },
//   { title: 'Gallery', path: '/gallery', theme: 'ligth', show: 'ever' },
//   { title: 'Marketplace', path: '/marketplace', theme: 'ligth', show: 'ever' },
//   { title: 'Sign In', path: '/login', theme: 'ligth', show: 'noLogged' },
//   { title: 'My Account', path: '/profile', theme: 'ligth', show: 'logged' },
//   { title: 'User Settings', path: '/profile-settings', theme: 'ligth', show: 'never' },
//   { title: 'Not Found', path: '/*', theme: 'ligth', show: 'never' },
]

const initialState: TuiState = {
  loading: true,
  showSidebar: false,
  showModal: false,
  activeView: views[0],
  views
}

const appUiSlice = createSlice({
  name: 'appUI',
  initialState,
  reducers:{
    setActiveView(state, action: PayloadAction<Tview>) {
      state.activeView = action.payload
    },
    hiddeSidebar(state) {
      state.showSidebar = false
    },
    displaySidebar(state) {
      state.showSidebar = true
    },
    hiddeLoader(state) {
      state.loading = false
    },
    showLoader(state) {
      state.loading = true
    }
  }
})

export const { 
  setActiveView,
  displaySidebar,
  hiddeSidebar,
  hiddeLoader,
  showLoader
} = appUiSlice.actions

export default appUiSlice.reducer