import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { TloginUserPayload, TresponseError, TuserProfileResponse, TsimpleResponse } from './userApiTypes'
import { loginUser, getUserProfile, logOut } from './api'
import Cookies from 'universal-cookie'

import { Tuser } from './userTypes'

const cookies = new Cookies()

const COOKIE_USER = 'carp10_user'

const initialState: Tuser = {
  status: 'idle',
  errorMsg: null,
  data: undefined
}

// InitSession
export const initSession = createAsyncThunk<
  TuserProfileResponse,
  TloginUserPayload,
  {
    rejectValue: TresponseError
  }
>('user/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await loginUser(payload)
    return response.data
  } catch(err: any) {
    let error: AxiosError<TresponseError> = err
    if (error.response) return rejectWithValue(error.response.data)
    throw err
  }
})

// FetchUserData 
export const fetchUserData = createAsyncThunk<TuserProfileResponse>
('user/profile', async () => {
  const response = await getUserProfile()
  return response.data
})

// Close Session
export const closeSession = createAsyncThunk<TsimpleResponse>
('user/logout', async () => {
  const response = await logOut()
  return response.data
})


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(initSession.pending, (state) => {
        state.status = 'loading'
        state.errorMsg = null
      })
      .addCase(initSession.rejected, (state, action) => {
        state.status = 'error'
        if (action.payload) state.errorMsg = action.payload.message
      })
      .addCase(initSession.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.errorMsg = null
        state.data = {
          ...action.payload
        }
        console.log(action.payload)
        cookies.set(COOKIE_USER, action.payload.email)
      })

      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading'
        state.errorMsg = null
      })
      .addCase(fetchUserData.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled'
        state.errorMsg = null
        state.data = {
          ...payload
        }
      })
      .addCase(fetchUserData.rejected, ((state, action) => {
        state.status = 'error'
        state.errorMsg = action.payload as string
      }))

      .addCase(closeSession.pending, (state) => {
        state.status = 'loading'
        state.errorMsg = null
      })
      .addCase(closeSession.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled'
        state.errorMsg = null
        state.data = undefined
      })
      .addCase(closeSession.rejected, ((state, action) => {
        state.status = 'error'
        state.errorMsg = action.payload as string
      }))
  }
})

export default userSlice.reducer