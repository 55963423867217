import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch } from '../../app/store'

import menuDesktop from '../../assets/icons/ui/loginDesktop.png'

export default function DesktopMenu() {
  const [active, setActive] = useState(false)
  const dispatch = useAppDispatch()

  const dropMenu = useRef<HTMLDivElement |null>(null);

  useLayoutEffect(() => {
    function clickBody(event: MouseEvent) {
      let target = event.target as Element
      const id = target.getAttribute('id')

      console.log('isActive: ', document.getElementById('carp10-login-dropmenu'))
      
      if (!target.closest('#carp10-login-dropmenu-button')) {
        if (!target.closest('#carp10-login-dropmenu') ) {
          setActive(false)
        }
      } 

      // if (id && !id.match(/foundx-user-dropdownmenu/)) {
      //   props.closeMenu()
      // }
      // if (!id) {
      //   props.closeMenu()
      // }
    }

    document.body.addEventListener('click', clickBody )

    return () => {
      document.body.removeEventListener('click', clickBody )
    }
  }, [])

  return(
    <DesktopMenuContainer>

      <IconContainer
        id='carp10-login-dropmenu-button'
      >
        <Icon
          src={menuDesktop}
          width={40}
          height={40}
          onClick={() => setActive(!active)}
        />
      </IconContainer>

      { active &&
        <DropMenu
          id='carp10-login-dropmenu'
          ref={dropMenu}
        >
          <FirstRow>
            <NavLink to="/login" className='no-decoration'>
              <InitLogin> Iniciar Sesión </InitLogin>
            </NavLink>

            <VerticalDivisor> | </VerticalDivisor>

            <NavLink to="/registro" className='no-decoration'>
              <SignUp> Crear Cuenta </SignUp>
            </NavLink>
          </FirstRow>

          <HorizonatlDivisor/>

          <SecondRow>
            <Inspecionar> Solicitar Inspección </Inspecionar>
          </SecondRow>
        </DropMenu>
      }

    </DesktopMenuContainer>
  )
}

const DesktopMenuContainer = styled.div`
  display: flex;
  justify-content: center;

`
  const IconContainer = styled.div`
    margin-left: 50px;
    cursor: pointer;
  `
  const Icon = styled.img`
    
  `
const DropMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  bottom: -40px;
  right: 90px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;

  background-color: white;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.1);
`
  const FirstRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
  `
  const HorizonatlDivisor = styled.div`
    width: 100px;
    border-bottom: solid 2px #fe7027;
    margin: 5px auto 5px auto;
  `
  const SecondRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  `
    const VerticalDivisor = styled.span`
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      margin: 0px 10px 0px 10px;
    `
    const InitLogin = styled.span`
      cursor: pointer;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    `
    const SignUp = styled.span`
      cursor: pointer;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    `
    const Inspecionar = styled.span`
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      margin-top: 5px;
    `