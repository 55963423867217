import React from 'react'
import styled from 'styled-components'

export default function PageNotFound() {

  return(
    <PageNotFoundContainer className='view'>
      <Error> 404 </Error>
      <Message> Page Not Found </Message>
    </PageNotFoundContainer>
  )
}

const PageNotFoundContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

const Error = styled.p`
  font-family: Montserrat;
  color: gray;
  font-weight: 800;
  font-size: 80px;
  line-height: 60px;
  margin-bottom: 0px;
`

const Message = styled.p`
  font-family: Montserrat;
  color: gray;
  font-weight: 800;
  font-size: 30px;
`