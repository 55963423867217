import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function WelcomeSection() {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <WelcomeContainer className='WelcomeContainer view'>
      <WelcomeMessage>
        Compra un vehículo usado
        <HighLight> revisado </HighLight> y
        <HighLight> verificado </HighLight> por carp10
      </WelcomeMessage>

      <SearchContainer isMobile={isMobile}>
        <SearchInput
          placeholder='Escribe marca o modelo de vehículo'
        />
        <SearchButton isMobile={isMobile}>
          BUSCAR
        </SearchButton>
      </SearchContainer>

    </WelcomeContainer>
  )
}

const WelcomeContainer = styled.div`
  background-color: #eeeeee;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`

const WelcomeMessage = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  padding: 20px 0px 20px 0px;
`
  const HighLight = styled.span`
    color: #FE7027;
    font-weight: 800;
  `
interface TsearchContainer { isMobile: boolean }
const SearchContainer = styled.div<TsearchContainer>`
  display: flex;
  flex-flow: ${(args) => args.isMobile ? 'column nowrap' : 'row nowrap'};
  margin: 30px auto 0px auto;
`
  const SearchInput = styled.input`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    width: 270px;
    padding: 10px;
  `
  interface TsearchButton { isMobile: boolean }
  const SearchButton = styled.button<TsearchButton>`
    background-color: #fe7027;
    border: none;
    color: white;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
    height: 38px;
    width: ${ (args) => args.isMobile ? '290px' : '100px' };
    margin: ${(args) => args.isMobile ? '10px auto 0px auto' : '0px 0px 0px 15px'};
  `