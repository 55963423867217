import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport';
import styles from './Home.module.css'

import WelcomeSection from './WelcomeSection';

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Home () {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  return(
    <HomeContainer>
      <WelcomeSection/>
    </HomeContainer>
  )
}

const HomeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`