import React from 'react'
import styled from 'styled-components'

import twitter from '../../assets/icons/ui/facebook.png'
import facebook from '../../assets/icons/ui/twitter.png'
import linkedin from '../../assets/icons/ui/linkedin.png'
import instagram from '../../assets/icons/ui/instagram.png'

import arroba from '../../assets/icons/ui/arroba.png'
import phone from '../../assets/icons/ui/phone.png'

import logo from '../../assets/icons/ui/logo_dark.png'

export default function AuthAdvice() {
  return(
    <AuthAdviceContainer>
      <SocialContainer>
        <SocialLinks>
          <Link> 
            <SocialIcon src={twitter} />
          </Link>
        </SocialLinks>
        <SocialLinks>
          <Link>
            <SocialIcon src={facebook} />
          </Link>
        </SocialLinks>
        <SocialLinks>
          <Link>
            <SocialIcon src={linkedin} />
          </Link>
        </SocialLinks>
        <SocialLinks>
          <Link>
            <SocialIcon src={instagram} />
          </Link>
        </SocialLinks>
      </SocialContainer>

      <TextContainer>
        <Text> -   Compra vehículos revisados y evaluados por carp10 </Text>
        <Text> -   Encontraras vehículos modelos 2010 hacia adelante </Text>
        <Text> -   Vehículos de las marcas más comerciales de Bolivia </Text>
      </TextContainer>

      <Logo src={logo}/>

      <ContacContainer>
        <TelfContainer>
          <ContactIcon src={phone}/>
          <ContacTitle> Teléfono </ContacTitle>
          <ContactInfo> +591 76000632 </ContactInfo>
        </TelfContainer>

        <EmailContainer>
          <ContactIcon src={arroba}/>
          <ContacTitle> E-mail </ContacTitle>
          <ContactInfo> info@carp10.com </ContactInfo>
        </EmailContainer>
      </ContacContainer>
    </AuthAdviceContainer>
  )
}

const AuthAdviceContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
const SocialContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 250px;
`
  const SocialLinks = styled.div`
    display: flex;
    flex-flow: row nowrap;
    
  `
    const Link = styled.a`
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      background-color: #30302C;
      width: 45px;
      height: 45px;
    `
    const SocialIcon = styled.img`
      height: 18px;  
    `
const TextContainer = styled.div`
  margin-top: 20px;
  padding: 0px 10px;
`
  const Text = styled.div`
    margin-top: 10px;
    white-space: pre-wrap;
    text-align: justify;
    font-family: Montserrat;
    font-weight: 600;
  `
const Logo = styled.img`
  width: 200px;
  margin-top: 40px;
`

const ContacContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
`
  const TelfContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  `
  const EmailContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  `
  const ContactIcon = styled.img`
    width: 20px;
    margin: auto;
  `
  const ContacTitle = styled.p`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
  `
  const ContactInfo = styled.div`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
  `