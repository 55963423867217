import { configureStore, Action } from "@reduxjs/toolkit"
import { ThunkAction } from "@reduxjs/toolkit"

import appUI from "./AppUISlice"
import user from "../services/auth/userSlice"
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"

//import rootReducer, { TrootState } from "./rootReducer"

const store = configureStore({
  reducer: {
    appUI,
    user
  }
})

// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

export type TrootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, TrootState, null, Action<string>>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TrootState> = useSelector

export default store