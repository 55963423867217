import React, { useState, useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom"
import useViewport from '../hooks/useViewport'
import styled from 'styled-components';
import './App.css';

import Home from '../views/home/Home'
import About from '../views/about/About'
import PageNotFound from '../views/404/pageNotFound'
import SignUp from '../views/sign-up/SignUp';
import SignIn from '../views/sign-in/SignIn';

import Header from '../components/header/header'
import Sidebar from '../components/sidebar/sidebar';

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

function App() {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return (
    <AppContainer
      isMobile={isMobile}
      className="App"
    >
      <Header/>
      { isMobile && <Sidebar/> }

      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/registro" element={<SignUp/>} />
        <Route path="/login" element={<SignIn/>} />
        {/* <Route path="/about" element={<About/>} /> */}
        <Route path='*' element={<PageNotFound/>} />
      </Routes>
    </AppContainer>
  );
}

interface TappContainer { isMobile: boolean }
const AppContainer = styled.div<TappContainer>`
  margin-top: ${(args) => args.isMobile ? '70px' : '100px'};
  min-height: ${(args) => args.isMobile ? 'calc(100vh - 70px)' : 'calc(100vh - 100px)'};
`

export default App;
